import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import Head from "../components/Head"
import AppCardView from "../components/AppCardView"
import "bootstrap/dist/css/bootstrap.min.css"
import appStyles from "./apps.module.scss"
// import { Button } from "react-;"

const AppPage = () => {
  const data = useStaticQuery(graphql`
    query {
      appInfo: allContentfulAppDetail(
        sort: { fields: publishedDate, order: DESC }
      ) {
        edges {
          node {
            appTitle
            googlePlayStoreLink
            slug
            #======
            appIcon {
              id
              file {
                url
              }
              title
              contentful_id
              fluid {
                src
              }
            }
            # ======
            screenShots {
              id
              title
              file {
                url
                details {
                  size
                  image {
                    width
                    height
                  }
                }
              }

              fluid {
                src
                sizes
              }
            }

            #=========
            apkFile {
              title
              description
              file {
                details {
                  size
                }
              }
            }
            #=========
            excerpt {
              json
            }
            publishedDate(formatString: "MMM Do, YYYY")
            updatedDate(formatString: "MMM Do, YYYY")
            apkFileRelativePath
          }
        }
      }
      # TODO: please also include ios apps extension to be include in the filter
      apkFile: allFile(filter: { extension: { eq: "apk" } }) {
        edges {
          node {
            size
            relativePath
          }
        }
      }
    }
  `)

    // const filterdData = data.appInfo.edges.filter(post => {
    //   // console.log({ apptitle: post.node.appTitle })
    //   return ( 
    //     post.node.appTitle !== "Customer telebirr App" &&
    //     post.node.appTitle !== "telebirr Partner"
    //   )
    // })

  return (
    <Layout>
      <div className={appStyles.cardContainer}>
        <Head title="Apps" />
        <h2 style={{ textAlign: "center", color: "#018FD3" }}>
          Ethio telecom Mobile Apps
        </h2>
        <ol className={appStyles.posts}>
          {/* {filterdData.map(post => { */}
          {data.appInfo.edges.map(post => {
            return (
              <li className={appStyles.post} key={post.node.slug}>
                <AppCardView post={post} />
              </li>
            )
          })}
        </ol>
      </div>
    </Layout>
  )
}

export default AppPage
